import React from "react";
import Carousel from "../../components/Carousel/Carousel";

function Project() {  
  return (
    <>
    <main>
        <Carousel />
      </main>
      
    </>
  );
}

export default Project;
